exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-experiments-6-4-drama-therapy-jsx": () => import("./../../../src/pages/experiments/6-4-drama-therapy.jsx" /* webpackChunkName: "component---src-pages-experiments-6-4-drama-therapy-jsx" */),
  "component---src-pages-experiments-forces-jsx": () => import("./../../../src/pages/experiments/forces.jsx" /* webpackChunkName: "component---src-pages-experiments-forces-jsx" */),
  "component---src-pages-experiments-return-of-spontaneous-circulation-jsx": () => import("./../../../src/pages/experiments/return-of-spontaneous-circulation.jsx" /* webpackChunkName: "component---src-pages-experiments-return-of-spontaneous-circulation-jsx" */),
  "component---src-pages-experiments-sound-texture-jsx": () => import("./../../../src/pages/experiments/sound-texture.jsx" /* webpackChunkName: "component---src-pages-experiments-sound-texture-jsx" */),
  "component---src-pages-experiments-vasoconstriction-jsx": () => import("./../../../src/pages/experiments/vasoconstriction.jsx" /* webpackChunkName: "component---src-pages-experiments-vasoconstriction-jsx" */),
  "component---src-pages-experiments-vectors-jsx": () => import("./../../../src/pages/experiments/vectors.jsx" /* webpackChunkName: "component---src-pages-experiments-vectors-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-jsx": () => import("./../../../src/pages/info.jsx" /* webpackChunkName: "component---src-pages-info-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */)
}

